import React from "react";
import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import { PageSeo } from "../components/Seo";
import { Copy } from "../components/Copy";
import { Container, Heading } from "../components/Page";

const Policy = ({ data }) => {
  const { frontmatter: { title, }, html: content } = data.markdownRemark;
  return (
    <Layout>
      <PageSeo
        tabTitleSegments={["Policy", title]}
        title={title}
        description=""
      />
      <Container>
        <div className="mx-auto max-w-wide">
          <Heading>{title}</Heading>
          <Copy className="mx-auto" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Container>
    </Layout>
  );
};

export default Policy;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }`;
